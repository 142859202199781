<!--电能设置--计量对象组---->
<template>
  <div id="energyDailyData" ref="main" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <!-- 页面标题 -->
    
    <a-row :style="{width: '100%', height: '100%'}">
        <a-col :span="4" :style="{ height: '100%'}">
            <div style="padding-right:5px;">
                <div class="header-title">
                {{ $t("energy.enedata034") }}
                </div>
            </div>
            <div class="side-menu">
                <a-menu
                    style="width: 256px"
                    mode="inline"
                    :inline-collapsed="collapsed"
                    :open-keys.sync="openKeys"
                    :default-open-keys="['0']"
                    @click="handleClick"
                    v-model="selectKeys"
                >
                    <template v-for="item in rptGroupList">
                    <a-menu-item v-if="!item.children" :key="item.no">
                        <a-icon type="pie-chart" />
                        <span>{{ item.text }}</span>
                    </a-menu-item>
                    <sub-menu v-else :key="item.no" :menu-info="item" :isdelete="isdelete"/>
                    </template>
                    
                </a-menu>
            </div>
        </a-col>
        <a-col :span="20" :style="{height: '100%'}">
            <div  :style="{width: '100%', height: '100%'}">
                <div class="display-between">
                    <!-- 条件选择2 -->
                    <div class="condition">
                    
                    
                        <div class="display-column" >
                        <!-- 目标年度 -->
                            <span>{{ $t("energy.enedata017") }}</span>
                            <a-date-picker  v-model="ecdate" :format="dateFormat" :disabled-date="disabledDate"/>
                        </div>

                        <div class="display-column chk-cls" ref="rp">
                            <!-- 显示对象 -->
                            <span>{{ $t("energy.enedata024") }}</span>
                            
                            <a-select 
                                mode="multiple"
                                v-model="selRptPoints" 
                                style="width:100%;max-height:32px;overflow: auto;"
                                @change="handleChange"
                            >
                                <a-select-option v-for="rp in rptPointList" :key="rp.key">
                                {{ rp.name }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div class="display-column">
                            <a-button type="primary" @click="selectedAll" :disabled="rptPointList.length<1">{{selName}}</a-button>
                        </div>
                    </div>
                    <a-button type="primary" @click="loadEnergyDailyData(2)" :disabled="selRptPoints.length<1">{{ $t("energy.enedata006") }}</a-button>
                </div>
                <div class="main">
                  <a-table  :style="{width: '100%', height: '100%'}"
                      :columns="columns"
                      :data-source="tableData"
                      :pagination="pagination"
                      :scroll="xscroll"
                      bordered
                      size="middle">
              
                      <template
                          v-for="col in editCols"
                          :slot="col"
                          slot-scope="text, record">
                      
                          <div :key="col">
                              <a-input
                                  v-if="record.edit"
                                  style="margin: -5px 0;width:180px"
                                  :value="text"
                                  :min="0"
                                  @change="e => handleValChange(e.target.value, record.key, col)"
                              />
                              <template v-else>
                              {{ text }}
                              </template>
                          </div>
                      </template>
                  </a-table>
                </div>
                <div class="footer">
                    <div>
                      <a-button type="primary" :disabled="selectKeys.length==0" icon="download" @click="downloadTemplateFile" :title="$t('energy.enedata529')" > {{$t('energy.enedata527')}}</a-button>
                  
                      <a-button type="primary" :disabled="selectKeys.length==0"  @click="upload"> {{$t('energy.enedata557')}} </a-button>
                    </div>
                    <div>
                        <a-button type="primary" :disabled="!editData" @click="csvout"> {{ $t("energy.enedata042") }} </a-button>
                        <a-button type="primary" :disabled="!editData" @click="save"> {{ $t("energy.enedata023") }} </a-button>
                    </div>
                </div>
            </div>
        </a-col>
    </a-row>

    <upload-dialog :title="energyDataUploadDialogTitle" :kind="rpt" :reportGroupNo="grNo"></upload-dialog>
  
  </div>
</template>

<script>
import SubMenu from "../components/measureGroup/eneMeasureGroupSubMenu.vue";
import EnergyDataUploadDialog from "./energyDataUploadDialog.vue";
import { getEnergyReportData ,saveEnergyReportData , downloadEnergyCSVDataWithURL } from "../../../api/energy";
import { downloadUrl } from "../../../utils/utils";
import moment from "moment";

export default {
 
  data() {
     return {
        rpt:'1', 
        selectKeys:[],
        rptGroupList:[],
        isdelete:false,
        loading:false,
        grNo:undefined,
        srcPointList:[],
        rptPointList:[],
        selRptPoints:[],
        allRptPoints:[],
        isSelAll:false,
        selName:this.$t("energy.enedata025"),
        columns:[],
        tableData:[],
        pagination:false,
        xscroll: { x: 1000,y:300 },
        ecdate:moment(),
        collapsed:false, 
        editCols:[],
        editData:false,
        openKeys: ["0"],
        energyDailyDataList:[],
        chartData:null,
        energyDailyDataId:'',
        yearList:[],
        year:0,
        rptKinds:[],
        date:'',
        month:'',
        dateFormat: 'YYYY/MM/DD',
        monthFormat: 'YYYY/MM',
        valueMap:new Map(),
        energyDataUploadDialogTitle:this.$t('energy.enedata560'),//'日报数据导入',
        energyDataUploadDialogVisible:false,
    };
  },
  activated(){
    console.log("activated");
    this.selectKeys = [];
    this.rptGroupList = new Array();
    this.rptPointList = new Array();
    this.srcPointList = new Array();
    this.allRptPoints = new Array();
    this.selRptPoints = new Array();
    this.isSelAll = false;
    this.selName = this.$t("energy.enedata025");
    this.editCols = new Array();
    this.columns = new Array();
    this.tableData = new Array();
    this.editData = false;
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.loadEnergyDailyData(0);
    }
  },
 
  mounted() {
    //     //实现自适应部分
    window.addEventListener("resize", () => this.tableSize());
  },
  watch: {
    openKeys(val) {
      console.log('openKeys', val);
    },
  },
  methods: {
        downloadTemplateFile(){
          let fileName = this.$t('energy.enedata563');//"日报批量录入模板文件";
          let content = "";
          this.selRptPoints;
          let rows = [28];
          
          rows[0] = this.$t('energy.enedata017');//'日期';
          rows[1] = this.$t('energy.enedata342');//'信号编号';
          rows[2] = //'TagPoint';
          rows[3] = this.$t('energy.enedata343');//'信号名称';
          rows[4] = this.$t('energy.enedata566');//'时/单位';
          for(let i=1;i<25;i++){
            rows[i+4] = ''+i;
          }
          
          let now  = new moment();
          let date = now.format('YYYY/MM/DD');
          this.selRptPoints.forEach(key => {
              this.srcPointList.forEach(point => {
                  if(point.key == key){
                      rows[0] = rows[0]+','+date;
                      rows[1] = rows[1]+','+ point.cata + '-' + point.id;
                      rows[2] = rows[2]+','+point.tagPoint;
                      rows[3] = rows[3]+','+point.signalName;
                      rows[4] = rows[4]+','+point.unit;
                      for(let i=1;i<25;i++){
                        rows[i+4] = rows[i+4]+','+'';
                      }
                  }
              });
          });
          console.log("rows",rows);
          rows.forEach(items => {
            content+=items+"\r\n";
          });
          console.log("content",content);
          this.download(content,fileName);
        },
    
        download(result,fileName){
            // 判断浏览器类型
            if ((navigator.userAgent.indexOf('compatible') > -1 &&
                navigator.userAgent.indexOf('MSIE') > -1) ||
                navigator.userAgent.indexOf('Edge') > -1) {
                //IE10或Edge浏览器
                const BOM = "\uFEFF";
                const csvData = new Blob([BOM+result], { type: "text/csv" });
                navigator.msSaveBlob(csvData, `${fileName}.csv`);
            } else {
                //非IE浏览器
                const csvContent = "data:text/csv;charset=utf-8,\uFEFF" + result;
                //使用a标签的download属性实现下载功能
                const link = document.createElement("a");
                link.href = encodeURI(csvContent);
                link.download = `${fileName}.csv`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },
        upload(){
          console.log('this>>',this);
          console.log('energyDataUploadDialogVisible>>',this.energyDataUploadDialogVisible);
          this.energyDataUploadDialogVisible = true;
        },
        csvout(){
            let params =  {
                action:2,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                reportType:this.rpt,
                selReportGroupNo:0,
                date:'',
                points:[],
            };
        
            params.selReportGroupNo = this.grNo;
            params.date = this.ecdate.format('YYYY/MM/DD');
            params.points = this.selRptPoints;
        

            console.log("downloadEnergyCSVDataWithURL Params",params);
            this.loading = true;

            downloadEnergyCSVDataWithURL('bivale/energy/data/report/download',params)
            .then((res) => {
                let name = (this.$store.getters.siteServiceTypeName?this.$store.getters.siteServiceTypeName+'-':'')+this.$t('energy.enedata034')+'-'+moment().format('YYYYMMDDHHmmssSSS')+'.zip';
                console.log("downloadEnergyCSVDataWithURL Result",name);
                downloadUrl(res,name);
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            })
            .finally(()=>{
                this.loading = false;
            });
        },
        handleValChange( value, key, column) {
              let v = new Number(value);
              // console.log(isNaN(v));
              if(isNaN(v)){
                  this.$message.error(this.$t("energy.enedata038"));//请输入数字。
                  return;
              }
              const newData = [...this.tableData];
              const target = newData.filter(item => key === item.key)[0];
              if (target) {
                  let name = key + '-' + column;
                  this.valueMap.set(name,true);
                  target[column] = value;
                  this.data = newData;
              }
              // console.log('target>',target[column]);
        },
        selectedAll(){
            if(!this.isSelAll){
                this.selRptPoints = this.allRptPoints;
                this.isSelAll = true;
                this.selName = this.$t("energy.enedata026");
            }else{
                this.selRptPoints = new Array();
                this.isSelAll = false;
                this.selName = this.$t("energy.enedata025");
            }
            this.tableSize();
        },
        handleClick(e) {
          console.log('handleClick', e);
          this.grNo = e.key;
          this.editData = false;
          this.loadEnergyDailyData(1);
        },
    loadEnergyDailyData(action){
      let params =  {
          action:action,
          clientId: this.$store.getters.clientId,
          siteId: this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,
          reportType:this.rpt,
      };
      if(action==1){
          params.selReportGroupNo = this.grNo;
      }else if(action==2){
          params.selReportGroupNo = this.grNo;
          params.date = this.ecdate.format('YYYY/MM/DD');
          params.points = this.selRptPoints;
      }
      this.columns   = [];
      this.tableData = [];
      this.editCols  = [];
      console.log("getEnergyReportData Params",params);
      this.loading = true;

      getEnergyReportData(params)
      .then((res) => {
        console.log("getEnergyReportData Result",res);
        if(action==0){
            this.rptGroupList = res.data.rptGroupList;
        }else if(action==1){
            let len = res.data.energyReportGroup.rptGroupList.length;
            this.rptPointList = new Array();
            this.allRptPoints = new Array();
            this.selRptPoints = new Array();
            this.srcPointList = new Array();
    
            if(len>0){

                for (let i = 0; i < len; i++) {
                    const element = res.data.energyReportGroup.rptGroupList[i];
                    let key = element.id+'@@'+element.cata+'@@'+element.text;
                    let rp = {key:key,name:element.signalName};
                    this.rptPointList.push(rp);
                    this.allRptPoints.push(key);
                    element.key = key;
                    this.srcPointList.push(element);
                }

                this.selRptPoints.push(this.allRptPoints[0]);
            }
            this.isSelAll = false;
            this.selName = this.$t("energy.enedata025");
        }else if(action==2){
            this.editCols = new Array();
            this.columns = res.data.columnList;
            for (let i = 1; i < this.columns.length; i++) {
                this.editCols.push('item'+i);          
            }
            console.log( ' this.editCols>>',this.editCols)
            this.tableData = res.data.bodyList;
            this.editData = this.tableData.length>0;
            this.tableSize();
        }
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },
    handleChange(e){
      console.log("handleChange",e);
      if(e.length == this.allRptPoints.length){
          this.isSelAll = true;
          this.selName = this.$t("energy.enedata026");
      }else{
          this.isSelAll = false;
          this.selName = this.$t("energy.enedata025");
      }
    },
    tableSize() {
        setTimeout(() => {
            let height = this.$refs.main.clientHeight;
            let rpheig = this.$refs.rp.clientHeight;
            let tableHeight = height - 200 - rpheig;
            // if(this.isSelAll){
            //     tableHeight -=20;
            // }
            if(this.selRptPoints.length>4){
                let width = this.$refs.main.clientWidth;
                this.xscroll.x = width;
            }else{
                this.xscroll.x = 1000;
            }
            this.xscroll.y = tableHeight;
            console.log(this.xscroll);
        }, 0);
    },
    disabledDate(startValue) {
      // console.log(`disabledDate1 = ${startValue}`);
      const endValue = moment();
      // console.log(`disabledDate2 = ${endValue}`);
      if (!startValue || !endValue) {
        return false;
      }
      return (
        startValue.valueOf() > endValue.valueOf() ||
        startValue >= moment().endOf()
      );
    },
    save(){
        let eneRptPointLogValues = this.getEneRptPointLogValues();
        let params =  {
          action:1,
          clientId: this.$store.getters.clientId,
          siteId: this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,
          reportType:this.rpt,
          selReportGroupNo :this.grNo,
          date : this.ecdate.format('YYYY/MM/DD'),
          points : this.selRptPoints,
          eneRptPointLogValues: eneRptPointLogValues
        };
        this.$confirm({
            title:this.$t("energy.enedata184"),
            centered: true,
            onOk: () => {
                console.log("saveEnergyReportData Param",params);
                this.loading = true;
                saveEnergyReportData(params)
                .then((res) => {
                    console.log("saveEnergyReportData Result",res);
                    if(res.errorCode ==='00' || res.errorCode ==='03'){
                        this.$message.info(res.msg);
                        this.loadEnergyDailyData(2);
                    }else{
                         this.$message.error(res.msg);
                    }                  
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                    console.log(err);
                });
            }
        }); 
    },
    getEneRptPointLogValues(){
        let eneRptPointLogValues = new Array();
        
        let rows = this.editCols.length;
        let cols = this.columns.length;
        let date = this.ecdate.format('YYYY/MM/DD');
         for (let i = 1; i < cols; i++) {
             const col = this.columns[i];
             const tagPoint = col.title;
             const columnName = 'item';
             const columnName1 = 'item'+i;
             for(let j=0;j<this.tableData.length;j++){
                 const element = this.tableData[j];
                 if(element.edit){
                      let change = true;//this.valueMap.get(element.key+'-'+columnName1);
                      if(change){
                          let hour = element.item.replace(":00","");

                          const rplv = {
                              id: 0,
                              category: "",
                              columnIndex: i,
                              date: hour,
                              rowIndex: j,
                              tagPoint: tagPoint,
                              value: element[columnName1]
                          } ;
                          // console.log(rplv.tagPoint,rplv.date,rplv.value)
                          eneRptPointLogValues.push(rplv);
                      }
                 }
             }
         }
        return eneRptPointLogValues;
    }
  },
  components: {
    'sub-menu': SubMenu,
    'upload-dialog':EnergyDataUploadDialog,
  },
  destroyed(){
       window.removeEventListener("resize", () => this.tableSize());
  },
};
</script>

<style scoped>
#energyDailyData {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
ul, li {
  list-style: none;
  margin: 0;
}
.ant-menu-submenu-title{
  border-bottom: 1px solid #eeeff3;
}

.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;
}
.header-title::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  background-color: #7682ce;
  margin-right: 20px;
}
.side {
  width: 280px;
  height: calc( 100% - 200px );
}
.side-menu{
  max-height: calc( 100% - 208px );
  overflow: auto;
}
  .main {
    width:100%;
    height: calc(100% - 100px);
    /* position: relative; */
    /* padding: 5px; */
  }
.chk-cls{
    width:calc( 100% - 300px );
}

.condition{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.condition:after{
    clear: both;
}

.display-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
  
}
.display-center {
  display: flex;
  justify-content: center;
}
.display-column {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.display-column span {
  font-size: 14px;
}


.histogram {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  box-shadow: 0 2px 15px #bdbcbc;
  border-radius: 3px;
  margin-bottom: 10px;
}
.chart-box{
  width: 100%;
  height: calc( 100% + 300px );
  
}
.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* height: 52px; */
  padding: 10px 5px;
  /* position: absolute;
  bottom: 0; */
}
.footer button{
    margin-left: 15px;
}
</style>